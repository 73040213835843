import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageWithCaption = makeShortcode("ImageWithCaption");
const TableOfContents = makeShortcode("TableOfContents");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ImageWithCaption imageName="banner-carousel-overview" type="png" alt="A banner carousel containing five slides." mdxType="ImageWithCaption" />
    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#when-to-use"
            }}>{`When to use`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#anatomy"
            }}>{`Anatomy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#specs"
            }}>{`Specs`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#variants"
            }}>{`Variants`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#behaviour"
            }}>{`Behaviour`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#usage-guidelines"
            }}>{`Usage guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#accessibility"
            }}>{`Accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <hr></hr>
    <p>{`Banner carousels can accommodate various types of content including text, images and videos. It includes visual navigation such as buttons, arrow controls and pagination to indicate that it is interactive.`}</p>
    <p>{`Users can control which slide is visible by using the arrow controls or pagination to navigate the banner carousel, or by swiping through it on touchscreen devices.`}</p>
    <h2 {...{
      "id": "when-to-use",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-to-use",
        "aria-label": "when to use permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When to use`}</h2>
    <p><strong parentName="p">{`Use a banner carousel:`}</strong></p>
    <ul>
      <li parentName="ul">{`To display content such as banners or illustrations designed to attract attention, serve as a key entry point or create moments of delight.`}</li>
      <li parentName="ul">{`To introduce new product features or updates to users, e.g. as part of an onboarding flow.`}</li>
    </ul>
    <p><strong parentName="p">{`Don't use a banner carousel:`}</strong></p>
    <ul>
      <li parentName="ul">{`If you're looking to display a sequence of similar items for browsing or comparison, e.g. product cards, we'd recommend using a `}<a parentName="li" {...{
          "href": "https://design-systems.sainsburys.co.uk/components/carousel/"
        }}>{`'Carousel'`}</a>{` instead.`}</li>
      <li parentName="ul">{`Refrain from displaying too many slides, i.e. more than 10 slides, in a banner carousel.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "anatomy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#anatomy",
        "aria-label": "anatomy permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Anatomy`}</h2>
    <ImageWithCaption imageName="banner-carousel-anatomy" type="png" alt="An example of a banner carousel with a slide, controls, pagination and a play and pause button." mdxType="ImageWithCaption" />
    <p>{`A banner carousel can include the following:`}</p>
    <ul>
      <li parentName="ul">{`(1) `}<strong parentName="li">{`Slide:`}</strong>{` this can include various types of content such as text, images and videos.`}</li>
      <li parentName="ul">{`(2) `}<strong parentName="li">{`Controls:`}</strong>{` buttons that allow the user to navigate to the previous or next slide.`}</li>
      <li parentName="ul">{`(3) `}<strong parentName="li">{`Pagination:`}</strong>{` a row of indicator buttons that denotes the current slide and allows the user to jump between slides.`}</li>
      <li parentName="ul">{`(4) `}<strong parentName="li">{`Play/Pause button (if autoplay is enabled):`}</strong>{` a button that allows the user to play or pause the scrolling movement of the banner carousel.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "specs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#specs",
        "aria-label": "specs permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Specs`}</h2>
    <h3>{`Width`}</h3>
    <ImageWithCaption imageName="banner-carousel-width" type="png" alt="Two examples of a banner carousel on mobile and desktop to show that the banner carousel has a minimum width of 272px." mdxType="ImageWithCaption" />
    <p>{`The banner carousel has a `}<strong parentName="p">{`minimum width of 272px`}</strong>{` to support the smallest screen width of 320px.`}</p>
    <p>{`On mobile devices, the banner carousel should usually take up 100% of the viewport width, inclusive of any left and right margins set at the page level.`}</p>
    <h3>{`Height`}</h3>
    <ImageWithCaption imageName="banner-carousel-height" type="png" alt="Two examples of a banner carousel on mobile and desktop to show that the height of all the slides inherits the height of the tallest slide." mdxType="ImageWithCaption" />
    <p>{`The height of all slides in the banner carousel should `}<strong parentName="p">{`inherit the height of the tallest slide`}</strong>{`. The height of the carousel container should not change while the user is navigating the banner carousel.`}</p>
    <p>{`When using images as the main slides in the banner carousel, `}<strong parentName="p">{`all of them should have the same height`}</strong>{`.`}</p>
    <h3>{`Pagination & controls`}</h3>
    <ImageWithCaption imageName="banner-carousel-pagination-controls" type="png" alt="Two examples of the pagination and controls of the banner carousel on mobile and desktop to show the differences in spacing between the elements." mdxType="ImageWithCaption" />
    <p>{`Within the pagination, there is `}<strong parentName="p">{`no spacing between the pagination dots`}</strong>{`.`}</p>
    <p>{`On viewport sizes `}<strong parentName="p">{`smaller than SM (576px)`}</strong>{`, there is no spacing between the arrow controls and pagination dots.`}</p>
    <p>{`On viewport sizes of `}<strong parentName="p">{`SM (576px) and larger`}</strong>{`, the arrow controls and pagination dots are separated by 12px spacing on both sides.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "variants",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#variants",
        "aria-label": "variants permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Variants`}</h2>
    <h3>{`Default controls`}</h3>
    <ImageWithCaption imageName="banner-carousel-default-controls" type="png" alt="Two examples of a banner carousel on mobile and desktop, with default controls that appear at the left and right side of the pagination." mdxType="ImageWithCaption" />
    <p>{`If the default arrow controls are enabled, they will appear at the left and right sides of the pagination and the banner carousel `}<strong parentName="p">{`cannot be externally controlled`}</strong>{` using any custom buttons.`}</p>
    <h3>{`Custom controls`}</h3>
    <ImageWithCaption imageName="banner-carousel-custom-controls" type="png" alt="Two examples of a banner carousel on mobile and desktop, with custom controls that are styled and positioned differently from the default controls on the page." mdxType="ImageWithCaption" />
    <p>{`If support for custom controls is enabled, the `}<strong parentName="p">{`default controls will be disabled and hidden from view`}</strong>{`. Instead, the banner carousel can be controlled using custom buttons that can be styled and positioned differently from the default controls.`}</p>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "#usage-guidelines"
      }}>{`usage guidelines`}</a>{` for guidance on how the external controls should be designed and labelled.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#behaviour",
        "aria-label": "behaviour permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Behaviour`}</h2>
    <h3>{`Pagination`}</h3>
    <ImageWithCaption imageName="banner-carousel-behaviour-pagination" type="png" alt="Two examples of pagination in a banner carousel, showing a maximum of 5 visible pagination dots." mdxType="ImageWithCaption" />
    <p>{`The pagination appears as a row of dots, each representing a slide in the banner carousel. The current slide is denoted by a solid horizontal line.`}</p>
    <p>{`Across all viewport sizes, there should be a `}<strong parentName="p">{`maximum of 5 visible pagination dots`}</strong>{`.`}</p>
    <p>{`If there are more than 5 slides in the banner carousel:`}</p>
    <ul>
      <li parentName="ul">{`The pagination will be clipped as there are too many dots to fit into the available space.`}</li>
      <li parentName="ul">{`The pagination dots at the edges will be slightly reduced in size to indicate that there are more slides than the number of visible pagination dots.`}</li>
    </ul>
    <h3>{`Autoplay`}</h3>
    <p>{`A banner carousel can be made to autoplay its scrolling movement. If autoplay is enabled, it will be turned on by default.`}</p>
    <h4>{`Play/Pause button`}</h4>
    <ImageWithCaption imageName="banner-carousel-behaviour-play-pause-button" type="png" alt="Two examples of a banner carousel on mobile and desktop with autoplay enabled, to show that the label of the play and pause button is hidden on mobile, but displayed on larger viewport sizes." mdxType="ImageWithCaption" />
    <p>{`Users who find the movement too fast or distracting can pause autoplay using a Play/Pause button, in accordance with `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/quickref/#pause-stop-hide"
      }}>{`WCAG 2.1 Guideline 2.2 – Enough Time`}</a>{`.`}</p>
    <p>{`If autoplay is enabled, the banner carousel will contain a Play/Pause button that allows users to start and stop autoplay. The button will be visible to all users, whether they are interacting with a mouse, keyboard, screen reader, or touch.`}</p>
    <p>{`On mobile devices, the label of the button is hidden. On tablet devices and larger, the label is displayed.`}</p>
    <p>{`If autoplay is disabled, the Play/Pause button will not be displayed.`}</p>
    <ImageWithCaption imageName="banner-carousel-behaviour-autoplay" type="png" alt="Two examples of a banner carousel with autoplay enabled, to show that the label of the play and pause button says 'Pause' when autoplay is on, and 'Play' when autoplay is off." mdxType="ImageWithCaption" />
    <p>{`In autoplay mode, the button will have a ‘Pause’ label. Clicking on it will pause autoplay.`}</p>
    <p>{`When autoplay is paused, the button will have a ‘Play’ label. Clicking on it will resume autoplay.`}</p>
    <h4>{`Duration`}</h4>
    <p>{`In autoplay mode, the default duration between content transitions is `}<strong parentName="p">{`5000ms (5 seconds)`}</strong>{`.`}</p>
    <p>{`Duration can be tailored to your needs by updating the ‘Duration’ property. As a ‘safe’ duration, we recommend 30 milliseconds for 1 word which takes into account the average reading speed of 200 words per minute (wpm).`}</p>
    <p>{`References:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wordsrated.com/reading-speed-statistics/#:~:text=The%20average%20reading%20speed%20for,is%20lower%20than%20100%20wpm."
        }}>{`Reading Speed Statistics – WordsRated`}</a></li>
    </ul>
    <h3>{`Scroll direction`}</h3>
    <ImageWithCaption imageName="banner-carousel-behaviour-scroll-direction" type="png" alt="Illustration showing the scroll direction of the slides in the banner carousel. Slides usually scroll into view from the right, but clicking on the 'previous' control will make the slide scroll in from the left." mdxType="ImageWithCaption" />
    <ul>
      <li parentName="ul">{`In autoplay mode, the next slide will scroll into view from the `}<strong parentName="li">{`right`}</strong>{`.`}</li>
      <li parentName="ul">{`Clicking on the right-arrow control or a subsequent pagination dot will make the next slide scroll into view from the right.`}</li>
      <li parentName="ul">{`Clicking on the left-arrow control or a preceding pagination dot will make the previous slide scroll into view from the left.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "usage-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage-guidelines",
        "aria-label": "usage guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Usage guidelines`}</h2>
    <h4>{`Don't enable autoplay if the banner carousel contains video or animated elements`}</h4>
    <ImageWithCaption imageName="banner-carousel-usage-guidelines-01" type="png" alt="An example of a banner carousel containing a video in the current slide with autoplay disabled." mdxType="ImageWithCaption" />
    <p>{`Autoplay should be `}<strong parentName="p">{`disabled`}</strong>{` in banner carousels that contain videos or animated elements.`}</p>
    <p>{`Enabling autoplay in this case could adversely affect users with cognitive conditions such as attention deficit disorder who are easily distracted by moving content. The additional movement from the videos or animated elements could prevent users from concentrating or interacting with the website.`}</p>
    <p>{`References:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2023/02/guide-building-accessible-carousels/"
        }}>{`A Step-By-Step Guide To Building Accessible Carousels — Smashing Magazine`}</a></li>
    </ul>
    <h4>{`Use properly labelled buttons for custom controls and position them close to the banner carousel`}</h4>
    <ImageWithCaption imageName="banner-carousel-usage-guidelines-02" type="png" alt="An example of a banner carousel with custom controls that are styled and positioned differently from the default controls." mdxType="ImageWithCaption" />
    <p>{`Custom controls should be `}<strong parentName="p">{`semantic button elements that include clear, intuitive labels or icons`}</strong>{`.`}</p>
    <p>{`For example, the custom controls can be labelled as “Previous” and “Next”, or use chevrons to indicate the previous and next slides. They should be labelled by `}<strong parentName="p">{`clear accessible names`}</strong>{` for screen reader users, e.g. “Previous slide” and “Next slide”. Refer to the ‘Accessibility’ section for more information.`}</p>
    <p>{`The custom controls should also be positioned close to the banner carousel container to indicate that they should be used to navigate the banner carousel.`}</p>
    <h4>{`Most important content always come first`}</h4>
    <ImageWithCaption imageName="banner-carousel-usage-guidelines-03" type="png" alt="A diagram demonstrating a decay in engagement the further a user advances through the banner carousel." mdxType="ImageWithCaption" />
    <p>{`It is suggested that there is a decline in the number of interactions, the further a user advances through the banner carousel. Hence, it is important to place your most important slides first to avoid them being missed by users.`}</p>
    <p>{`References:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2015/02/carousel-usage-exploration-on-mobile-e-commerce-websites/#interaction-decay-rates"
        }}>{`Smashing Magazine - Kyle Peatt`}</a></li>
    </ul>
    <h4>{`Limit the height of the slides to 50% of the screen’s height (max)`}</h4>
    <ImageWithCaption imageName="banner-carousel-usage-guidelines-04" type="png" alt="Example of a banner carousel showing that its height should not exceed 50% of the screen height." mdxType="ImageWithCaption" />
    <p>{`Research shows that on mobile, we tend to interact with pages by reading, swiping and scrolling in the centre of the screen. With this in mind, a carousel with a maximum height of 50% allows users to position the component in their preferred position of the screen to then interact with it.`}</p>
    <p>{`References:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.uxmatters.com/mt/archives/2017/03/design-for-fingers-touch-and-people-part-1.php"
        }}>{`Design for Fingers, Touch, and People - Steven Hoober`}</a></li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Accessibility`}</h2>
    <h3>{`Autoplay`}</h3>
    <p>{`People require different lengths of time to read content, and some people with cognitive conditions such as attention deficit disorder may find moving content distracting. While the banner carousel supports autoplay, it is important that users are able to easily stop or control the autoplaying movement.`}</p>
    <p>{`Once a user has explicitly interacted with an autoplaying banner carousel, autoplay will be paused. This includes:`}</p>
    <ul>
      <li parentName="ul">{`Focusing on the slide or pagination`}</li>
      <li parentName="ul">{`Clicking on the slide, arrow controls or pagination dots`}</li>
    </ul>
    <p>{`Autoplay only resumes if the user explicitly activates the Play/Pause button.`}</p>
    <p>{`References:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2023/02/guide-building-accessible-carousels/"
        }}>{`A Step-By-Step Guide To Building Accessible Carousels — Smashing Magazine`}</a></li>
    </ul>
    <h3>{`Tab order & screen reader behaviour`}</h3>
    <p>{`The banner carousel is carefully constructed to ensure that it is screen reader-friendly. Semantic button elements with clear accessible names are used for all interactive controls in the banner carousel.`}</p>
    <ImageWithCaption imageName="banner-carousel-screen-reader-behaviour-01" type="png" alt="An example of a banner carousel with accessibility annotations to show that the banner carousel container is the first element that receives focus in a banner carousel." mdxType="ImageWithCaption" />
    <p>{`(1) The banner carousel container is the first element that receives focus when the keyboard or screen reader user accesses the banner carousel.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`<section>`}</inlineCode>{` tag (with implicit `}<inlineCode parentName="p">{`role="region"`}</inlineCode>{`) with the `}<inlineCode parentName="p">{`aria-roledescription=“Banner carousel”`}</inlineCode>{` attribute is used for the container, so screen reader users know exactly where it starts and ends in the DOM.`}</p>
    <p>{`Each banner carousel should also be assigned an `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` attribute. This helps to distinguish between multiple banner carousels on a page.`}</p>
    <ImageWithCaption imageName="banner-carousel-screen-reader-behaviour-02" type="png" alt="An example of a banner carousel with accessibility annotations to show that the play and pause button is the second element that receives focus in a banner carousel." mdxType="ImageWithCaption" />
    <p>{`(2) Next, the keyboard or screen reader user accesses the Play/Pause button. This enables people who find moving content distracting to easily stop autoplay.`}</p>
    <ImageWithCaption imageName="banner-carousel-screen-reader-behaviour-03" type="png" alt="An example of a banner carousel with accessibility annotations to show that the pagination is the third element that receives focus in a banner carousel. Arrow keys are used to navigate between the pagination dots." mdxType="ImageWithCaption" />
    <p>{`(3) Next, the keyboard or screen reader user accesses the pagination.`}</p>
    <p>{`When the pagination receives focus, so does the active pagination dot. `}<strong parentName="p">{`Arrow keys are used to navigate between the pagination dots`}</strong>{`, not the Tab key. This enables users to quickly tab from the pagination to the slide.`}</p>
    <ImageWithCaption imageName="banner-carousel-screen-reader-behaviour-04" type="png" alt="An example of a banner carousel with accessibility annotations to show that the slide is the last element that receives focus in a banner carousel." mdxType="ImageWithCaption" />
    <p>{`(4) Lastly, the keyboard or screen reader user accesses the slide.`}</p>
    <p>{`If the slide itself is interactive, it will be announced as a link along with its role (e.g. image) and aria-label (accessible name).`}</p>
    <p>{`If the slide contains any other interactive elements, the user will tab through them before exiting the banner carousel container.`}</p>
    <p>{`References:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/carousel/examples/carousel-2-tablist/"
        }}>{`Auto-Rotating Image Carousel with Tabs for Slide Control Example | APG | WAI | W3C`}</a></li>
    </ul>
    <h3>{`Keyboard interactions`}</h3>
    <p>{`Keyboard navigation is important for helping users who do not use a mouse or pointer device. All interactive elements like links, buttons and controls must be able to receive focus and be operable using standard keyboard controls.`}</p>
    <p>{`Since keyboard users can use arrow keys to navigate the banner carousel, we have excluded the “previous” and “next” controls from the tab order, so that they don’t receive keyboard focus unnecessarily.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: Moves focus to the first element in the banner carousel, and to subsequent elements following the tab order.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`←`}</inlineCode>{` or `}<inlineCode parentName="li">{`→`}</inlineCode>{`: When the pagination is in focus, they are used to navigate to the previous or next slide.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Spacebar`}</inlineCode>{` or `}<inlineCode parentName="li">{`Enter`}</inlineCode>{`: Activates the interactive element in focus.`}</li>
    </ul>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using accordions or give you a helping
  hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      